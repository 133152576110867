import { render, staticRenderFns } from "./organisation.vue?vue&type=template&id=11f6044e"
import script from "./organisation.vue?vue&type=script&lang=js"
export * from "./organisation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <v-container class="fill-height" fluid>
    <v-row>
      <v-col cols="5">
        <v-card>
          <v-card-title class="subheading">Organisation details</v-card-title>
          <v-card-text>
            <v-form ref="form" @submit.prevent="validateBeforeSubmit">
              <v-col cols="12">
                <v-text-field
                  v-model="vm.name"
                  label="Organisation Name"
                  v-validate="'required'"
                  data-vv-as="name"
                  name="name"
                  :error-messages="errors.collect('name')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="vm.providerRegistrationNumber"
                  label="Provider Registration Number "
                  v-validate="'required'"
                  data-vv-as="providerRegistrationNumber"
                  name="providerRegistrationNumber"
                  :error-messages="errors.collect('providerRegistrationNumber')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <address-picker
                  :detail-mode="false"
                  label="Address"
                  :min-characters="4"
                  v-model="vm.address1"
                  v-validate="'required'"
                  required="true"
                  data-vv-as="address1"
                  name="address1"
                  :error-messages="errors.collect('address1')"
                ></address-picker>
              </v-col>

              <v-text-field
                v-model="vm.phonenumber"
                label="phonenumber"
                v-validate="'required'"
                data-vv-as="phonenumber"
                name="phonenumber"
                :error-messages="errors.collect('phonenumber')"
              ></v-text-field>

              <v-col cols="12">
                <v-text-field
                  v-model="vm.website"
                  label="Website"
                  data-vv-as="website"
                  name="website"
                  :error-messages="errors.collect('website')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="vm.abn"
                  label="ABN"
                  data-vv-as="aBN"
                  name="aBN"
                  :error-messages="errors.collect('aBN')"
                ></v-text-field>
                <v-text-field
                v-model="vm.salesAccountCode"
                label="Sales Account Code"
                v-validate="'required'"
                data-vv-as="accountcode"
                name="accountcode"
                :error-messages="errors.collect('accountcode')"
              ></v-text-field>

               <v-text-field
                  v-model="vm.taxType"
                  label="General Ledger Tax Type"
                  v-validate="'required'"
                  data-vv-as="taxtype"
                  name="taxtype"
                  :error-messages="errors.collect('taxtype')"
                ></v-text-field>

              </v-col>

              <v-btn color="primary" type="submit">update</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="7" class="pl-0">
        <v-card class="mb-2">
          <v-card-title class="subheading">Organisation logo</v-card-title>
          <v-card-text>
            Upload your corporate branding to be used on emails sent to your participants.
            <v-col cols="12">
              <v-img
                v-if="vm.logo"
                v-bind:src="vm.logo.downloadURL"
                alt="organisation logo"
                aspect-ratio="1"
                contain
                max-width="300"
                max-height="150"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <upload
                class="ml-4 pl-2"
                v-bind:path="logoUrl"
                buttonText="Upload logo"
                v-on:uploadComplete="orgLogoUploaded"
              ></upload>
            </v-col>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title class="subheading">Members</v-card-title>
          <v-card-text>
            <users v-bind:org="currentOrganisation"></users>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import users from "../../components/users";
import AddressPicker from "../../components/core/AddressPicker";
Vue.use(VeeValidate);

export default {
  data: () => ({
    vm: {},
    logoUrl: ""
  }),
  components: {
    users: users,
    AddressPicker
  },
  mounted() {
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    }
  },
  methods: {
    init() {
      this.vm =
        this.$store.getters.currentOrganisation == undefined
          ? {}
          : this.$store.getters.currentOrganisation;

      let basePath = this.currentOrganisation.id + "/";
      this.logoUrl = basePath + "logo/";
    },
    orgLogoUploaded(data) {
      this.vm.logo = data;
      this.$store.dispatch("setCurrentOrganisation", this.vm);
      this.$forceUpdate();
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$store.dispatch("setCurrentOrganisation", this.vm);
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    }
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        this.$router.push("/");
      }
    }
  }
};
</script>
